.sidebar-parent {
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 250px;
}
.sidebar {
    background-color: transparent;
    color: #00998C;
    padding: 12px;
    border-radius: 10px;
       border: 1px dashed #00998C ;

  }
  
  .sidebar .text-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar .text-box ul li {
    margin-bottom: 10px;
  }
  
  .sidebar .text-box h3 {
    margin: 0;
    font-size: 15px;
    padding: 0;
    line-height: 1;
  }
  
  .sidebar .text-box p {
    font-size: 15px;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  
  .sidebar .help-section {
    color: #00998C;
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
    line-height: 1;
  }
  
  .sidebar .help-section p {
    margin: 30px 0 10px;
  }
  
  .sidebar .help-section button {
    background-color: #00998C;
    border: 2px solid #00998C;
    color: #01382E;
    font-weight: 600;
    font-size: 16px;
    padding: 7px 14px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    width: 100%;
  }

  .sidebar .help-section button:hover {
    background-color: #1F6357;
    color: #FCF7FF;
    border: 2px solid #1F6357;
  }

  .addfirm-sidebar {
    background-color: transparent;
    color: #1F6357;
    padding: 12px;
    border-radius: 10px;
       border: 1px dashed #1F6357 ;
       margin-top: 20px;

  }

  .addfirm-sidebar h3 {
    margin: 0;
    font-size: 15px;
    padding: 0;
    line-height: 1;
  }
  
  .addfirm-sidebar p {
    font-size: 15px;
    margin: 0;
    padding: 0;
    line-height: 1;
    margin: 0 0 10px;
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
    line-height: 1;
  }

  .addfirm-sidebar button {
    background-color: #1F6357;
    border: 2px solid #1F6357;
    color: #01382E;
    font-weight: 600;
    font-size: 16px;
    padding: 7px 14px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    width: 100%;
  }

  .addfirm-sidebar button:hover {
    background-color: #00998C;
    color: #01382E;
    border: 2px solid #00998C;
  }

  @media screen and (max-width: 768px) {
    .sidebar-parent {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }
  }