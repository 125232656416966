.hero-container {
  /* Add any necessary styling for the hero container */
}

.text-div {
  margin: 50px 0 30px 0;
}

.hero-title {
  font-size: 62px;
  line-height: 1.2;
  margin-bottom: 18px;
  margin-top: 10px;
}

.typed-box {
  display: inline-block;
  white-space: wrap;
}

.hero-subtitle {
  font-size: 18px;
  font-weight: 400;
  max-width: 800px;
  /* color: #00998C; */
}

.blocks {
    display: none;
}

.tablet-blocks {
    display: none;
   }

@media screen and (max-width: 768px) {
    .text-div {
      margin: 0;

    }
  
    .hero-title {
      font-size:  32px;
      line-height: 1;
      margin-bottom: 10px;
    }
  
    .typed-box {
      white-space: wrap;
        position: absolute;
    }
  
    .hero-subtitle {
      font-size: 16px;
      font-weight: 400;
      max-width: 800px;
      /* color: #00998C; */
    }
  
    .tablet-blocks {
      display: block;
     }
  }


  @media screen and (max-width: 1000px) {
    .tablet-blocks {
      display: block;
     }
  }
 
  @media screen and (max-width: 313px) {
    .blocks {
      display: block;
  }
  }