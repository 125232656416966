/* styles.css */
.parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #01382E;
    height: 60px;
    margin: auto;
    width: 80%;
}

.left,
.right {
    display: flex;
    align-items: center; /* Align items in the center */
}

.logo {
    margin: 0;
    padding: 0;
    font-family: "Noto Serif JP", serif;
}

.logo a {
    text-decoration: none;
    color: #F6F4F4;
    font-size: 24px;
    margin: 0;
    padding: 0;
}

.nav-item {
}

.nav-item a {
    text-decoration: none;
    color: #F6F4F4;
}

.nav-item a:hover {
    text-decoration: underline;
}

.hamburger {
    display: none;
    font-size: 30px;
    cursor: pointer;
}

.mobile-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #01382E;
    padding: 10px;
}

.mobile-menu .nav-item {
    margin: 10px 0;
}

@media (max-width: 768px) {
    .parent {
        justify-content: space-between;
        align-items: center;
        height: 40px; /* Ensure height is enough for logo */
        width: 90%;
        padding: 0 10px; /* Add padding to ensure content doesn't touch edges */
        }

    .logo {
        font-size: 18px; /* Adjust font size for mobile view */
    }

    .hamburger {
        display: block; /* Display hamburger menu on mobile */
    }

    .right {
        display: none; /* Hide the right section on mobile to avoid overlap */
    }

    .mobile-menu {
        display: flex; /* Display the mobile menu when hamburger is clicked */
    }
}
