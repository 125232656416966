.container {
    max-width: 1400px;
    width: 80%;
    margin: auto;
}
  
  .main-content {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      
      width: 90%;

  }
    
    .main-content {

      flex-direction: column-reverse;
    }
  }